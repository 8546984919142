@import url("stylesheet.css");
body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #000000;
  height: 100vh; /* Ensure the body takes the full viewport height */
}

.login-font {
  font-family: 'Pockota Light';
  text-decoration: none;
}
.dashboard {
  height: 100vh;
  background-color: #000000;
  overflow: hidden;
  display: flex;
}
.sidebar {
  width: 265px;
  background-color: #000000;
  padding: 20px;
  color: white;
  border-right: 2px solid #202020;
  transition: width 0.7s ease;
  position: relative;
}
.sidebar.collapsed {
  width: 80px;
  padding: 10px 10px;
}
.toggle-btn {
  position: absolute;
  top: 20px;
  right: -12px;
  background: none;
  border: 2px solid #484848; /* Border color and thickness */
  border-radius: 70%; /* Makes the button circular */
  color: white;
  font-size: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; /* Center the icon within the button */
  width: 20px; /* Adjust width */
  height: 20px; /* Adjust height */
  padding: 10px; /* Add padding if needed */
  background-color: #000000;
}

.logout-btn {
  position: absolute;
  top: 20px;
  right: 80px; /* Adjust position as needed */
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.profile {
  text-align: center;
}
.profile img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: block;
  margin: 0 auto;
}
.profile-name,
.profile-role {
  display: block;
}
.profile-role {
  margin-top: 5px;
  color: #888;
}
.nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.nav li {
  margin: 10px 0;
  font-size: 16px;
  cursor: pointer;
  padding: 20px 20px;
  border-radius: 20px;
  background-color: #000000;
  color: white;
  display: flex;
  align-items: center;
}
.nav a:hover {
  transform: scale(1.05); /* Slightly enlarge the template on hover */
  background-color: #333333;
  border-radius: 10px;
}
.nav li img {
  width: 24px;
  height: 24px;
  border-radius: 20px;
}
.nav-text {
  transition: opacity 10s ease, visibility -5s ease;
  opacity: 1;
  visibility: visible;
}
.sidebar.collapsed .nav-text {
  opacity: 0;
  visibility: hidden;
}.sidebar.collapsed .footerdiv div {
  display: none;
}
.footer {
  position: absolute;
  bottom: 50px;
  width: 100%;
  max-width: 240px;
  padding: 10px;
  background-color: #282828;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  box-sizing: border-box;
  overflow: hidden;
}
.sidebar.collapsed .footer {
  max-width: 60px;
}
.footerdiv {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 0%;
}
.footerdiv img {
  width: 35px;
  height: 40px;
  margin-right: 10px;
  background-color: white;
  border-radius: 5px;
  padding: 0px 5px;
  transition: transform 0.3s ease-in-out; /* Smooth transition effect */
}

.footerdiv img:hover {
  transform: scale(1.2); /* Magnify the image by 1.2x */
}



.footerdiv div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.footerdiv p {
  margin: 0px;
  color: white;
  text-align: left;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.footerdiv p.bart {
  color: grey;
  text-align: left;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.footerdiv img.arrow {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  position: absolute;
  padding: 0;
  background-color: #282828;
}
.content {
  flex: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  box-sizing: border-box; /* Ensures padding and border are included in the width */
  overflow-x: auto; /* Prevents horizontal overflow */
  overflow-y: auto; /* Allows vertical scrolling if needed */
  height: 98vh;
  background-color: #282828;
  background: linear-gradient(to right, #202020 0%, #171852 100%);
  border: 2px solid #282828;
  margin-top: 10px;
  margin-right: 20px;
  margin-bottom: 10px;
  margin-left: 28px;
  
}
.content-box {
  height: 100vh;
  overflow-y: auto;
  position: relative;
}

.content-box::-webkit-scrollbar
{
  width: 0px;
}

.suggested-templates {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 5rem 10rem;
  width: 900px;
  color: white;
  /* position: fixed; */
}
.templates-header {
  text-align: center;
  font-size: 24px;
  color: white;
  margin-bottom: 0px;
  margin-top: 200px;
}
.template {
  background-color: #262626;
  padding: 20px;
  border-radius: 20px;
  color: white;
  text-align: left;
  cursor: pointer;
}
.suggested-templates > .template {
  background-color: #1f1f1f;
  border-radius: 25px;
  padding: 20px;
  text-align: left;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: white;
  border: 2px solid #282828;
  /* width: 250px; */
  height: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-decoration: none; /* Removes underline from links */
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.suggested-templates > .template:hover {
  transform: scale(1.05); /* Slightly enlarge the template on hover */
  background-color: #333333; /* Darken background on hover */
}

.template-icon {
  margin-right: 10px;
  width: 24px;
  height: 24px;
  margin-bottom: 5px;
}

.suggested-templates > .template p {
  text-align: left;
  font-size: 16px;

  color: #ffffff; /* Ensure text color is white */
}

.chat {
  display: flex;
  justify-content: space-between;
  background-color: #262626;
  padding: 10px 20px;
  border-radius: 50px;
  border: 1px solid #404040;
  position: absolute;
  bottom: 25px;
  left: 25px; /* Default left position when sidebar is expanded */
  right: 25px; /* Right side remains fixed */
  margin-top: 121px;
  transition: left 0.7s ease; /* Smooth transition for left property */
}

/* When the sidebar is collapsed */
.sidebar.collapsed ~ .content .chat {
  left: 150px; /* Adjust this value based on collapsed sidebar width */
}

.chat input {
  flex: 1;
  border: none;
  background: none;
  color: white;
  font-size: 16px;
  padding: 10px;
}
.chat input:focus-visible {
  outline: none !important;
}
.chat button {
  background-color: #444;
  color: white;
  border: none;
  padding: 0px 0px;
  border-radius: 50%;
  cursor: pointer;
}
.icon1 {
  border-radius: 50%;
  background-color: #262626;
  border: none;
  padding: 20px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logout-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Align the icon and button to the right */
}

.logout-icon {
  width: 40px; /* Adjust icon size */
  height: 40px;
  cursor: pointer;
}

.logout-confirm {
  background-color: #2c2c2c;
  color: grey;
  border: 1px solid #404040;
  padding: 8px 16px;
  border-radius: 30px;
  cursor: pointer;
  position: absolute;
  top: 40px; /* Adjust this value to place the button below the icon */
  right: 0; /* Align the button to the right */
  width: fit-content; /* Ensure the button is only as wide as the text */
  z-index: 1; /* Ensure the button appears above other content */
  box-shadow: 0 4px 6px rgba(34, 4, 4, 0.839); /* Optional: Add shadow for dropdown effect */
  display: flex;
  justify-content: center;
  margin-top: 8px; /* Ensure space between the icon and the button */
}

.settings-container {
  padding: 2rem;
  overflow: auto;
  color: #fff;
}

h3 {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #888;
}

.settings-section {
  background-color: #2c2c2c;
  border-radius: 20px;
}

.settings-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  border-bottom: 1px solid #3a3a3a;
  text-decoration: none;
  color: #ffffff;
}

.settings-option:last-child {
  border-bottom: none;
}

.option-label {
  font-size: 16px;
}

.option-value {
  font-size: 14px;
  color: #888;
}

.option-arrow {
  color: #888;
  font-size: 18px;
}

/* Add hover effect */
.settings-option:hover {
  background-color: #3a3a3a;
  border-radius: 20px;
}

.logoutsettings {
  color: red;
  font-style: bold;
}

.header {
  padding: 30px 20px;
}

.background {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: url("https://source.unsplash.com/random");
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

/* This blurs the entire background */
.background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px); /* This applies the blur effect */
  z-index: 1;
}

/* Foreground content (unblurred) */
.foreground {
  position: relative;
  z-index: 2; /* This keeps the content on top of the blur */
  top: 10rem;
}

.agent-container {
  display: flex;
  width: calc(100%-240px);
  border-radius: 50px;
  height: 100vh;
  background: linear-gradient(to right, #202020 0%, #171852 100%);
  color: #ffffff;
}




.chat-message {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px; /* Add margin between individual messages */
}

.message-row {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.avatar {
  margin-right: 20px; /* Adjust this value to set the gap between the logo and the text */
  flex-shrink: 0;
  width: 32px;
  height: 32px;
}

.message-info {
  flex: 1;
}

.header {
  display: flex;
  align-items: center;
  margin: 0; /* Remove margin */
  padding: 0; /* Ensure no padding */
}

.user-name,
.agent-name {
  font-weight: bold;
}

.timestamp {
  color: gray;
  margin-left: 4px; /* Adjust as needed */
}

.message-text {
  margin: 8px 0; /* Add 5px padding between header and message text */
  padding: 0; /* Remove any padding if present */
}

.otp-input-card {
  background-color: #1f1f2e; /* Dark background color */
  border-radius: 15px; /* Rounded corners */
  padding: 20px; /* Increased padding for more space */
  border: 1px solid #404040;
  margin: 10px; /* Center the card horizontally */
  width: 100%; /* Full width */
  max-width: 400px; /* Restrict max width */
  text-align: center; /* Center text inside */
  color: white; /* Text color */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3); /* Soft shadow */
}

.otp-label {
  margin-bottom: 10px; /* More space below label */
  font-size: 22px; /* Slightly larger font */
  font-weight: bold; /* Bold text */
}

.otp-inputs {
  display: flex; /* Flex layout */
  justify-content: space-between; /* Even spacing between inputs */
  margin: 20px 0; /* Margin for spacing */
  max-width: 100%; /* Maximum width for container */
}

.otp-inputs input {
  width: 40px; /* Wider input fields */
  height: 40px; /* Taller input fields */
  text-align: center; /* Center text */
  font-size: 24px; /* Larger font size */
  border: 2px solid #4d4d5e; /* Slightly thicker border */
  border-radius: 10px; /* Rounded corners */
  background-color: #1f1f2e; /* Consistent background */
  color: #ffffff; /* White text */
  transition: border-color 0.3s ease; /* Smooth transition on focus */
}

.otp-inputs input:focus {
  border-color: #7c5cfc; /* Highlight border on focus */
  outline: none; /* No default outline */
}

.otp-submit-btn {
  background: linear-gradient(
    90deg,
    
    #9198e5 0%,
    #e66465 100%
  );
  color: white; /* White text */
  border: 1px solid #404040;
  padding: 10px 20px; /* Larger button */
  border-radius: 10px; /* More rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth hover transition */
  font-size: 18px; /* Larger font size */
  font-weight: bold; /* Bold text */
  margin-top: 5px; /* Space above the button */
  margin-right: 400px; /* Space above the button */
}

.otp-submit-btn:hover {
  background-color: #6a4ee0; /* Darker background on hover */
}
.face-recognition-btn {
  background-color: #f787f5;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.face-recognition-btn:hover {
  background-color: #f787f5;
}

.application-options {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.application-options button {
  background-color: #2d2d3e;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.application-options button:hover {
  background-color: #3d3d4e;
}

.chat-input-container {
  display: flex;
  background-color: #2d2d3e;
  border-radius: 30px;
  padding: 10px;
  margin: 20px;
}

.chat-input {
  flex: 1;
  border: none;
  background: none;
  color: white;
  font-size: 16px;
  padding: 10px;
}

.chat-input:focus {
  outline: none;
}


.hidden-scrollbar::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.send-button,
.attach-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.send-icon,
.attach-icon {
  width: 42px;
  height: 42px;
}

.facial-auth-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.option-card,.video-verification-card {
  display: flex;
  margin-bottom: 10px;
  background-color: #2d2d3e;
  color: rgb(252, 246, 246);
  border: 1px solid #404040;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.option-card:hover,.video-verification-card:hover {
  background: linear-gradient(to right, #e189b0 0%, #6b1df5 100%);
}

.option-card.selected, .video-verification-card.selected {
  background: linear-gradient(to right, #e189b0 0%, #6b1df5 100%) !important;
}

/* Additional specificity to ensure selected styles persist on hover */
.option-card.selected:hover, .video-verification-card.selected:hover {
  background: linear-gradient(to right, #e189b0 0%, #6b1df5 100%) !important;
}

.option-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  
}


.dot-container {
  display: flex;
  justify-content: space-between;
  width: 30px; /* Reduce the width for closer spacing */
}

.dot {
  width: 6px; /* Smaller dot size */
  height: 6px;
  background-color: #fff;
  border-radius: 50%;
  animation: bounce 0.8s infinite ease-in-out; /* Faster animation */
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.1s;
}

.dot:nth-child(3) {
  animation-delay: 0.2s;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px); /* Lower bounce height due to smaller size */
  }
}
.gradient-bar {
  margin: 0.5rem 0;
  width: 7px;
  border-radius: 0.5rem;
  background: linear-gradient(to bottom, #6b1df5 0%, #e189b0 100%);
  height: auto;

}
.avatar {
  width: 35px; /* Adjust the width as needed */
  height: 35px; /* Adjust the height as needed */
  border-radius: 50%; /* This makes the image circular */
  object-fit: cover; /* This ensures the image covers the circle without distortion */
}
.all-templates-header {
  text-align: center;
  font-size: 24px;
  color: white;
  margin-bottom: 0px;
  margin-top: 50px;
}



/* from here it is for the ticket card */

.message-content {
  font-size: 14px;
  line-height: 1.5;
}


.ticket-card {
  background-color: #1f1f2e;
  color: #fff;
  border-radius: 12px;
  padding: 15px;
  margin-bottom: 15px;
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease;
  width: 350px; /* Set your desired width */
  height: 100px; /* Set your desired height */
  border: 1px solid #404040;

}


.ticket-card:hover {
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
}

.ticket-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.ticket-time-urgency {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #8e8e93;
}

.ticket-urgency {
  color: #c02921; /* Red for urgent */
  /* font-weight: bold; */
}

.ticket-title {
  /* font-weight: bold; */
  font-size: 16px;
  margin-top: 5px;
  
}
.ticket-details {
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 30px; /* Adjust this value to control the spacing between items */
}

.ticket-label {
  font-size: 12px;
  color: #8e8e93;
  margin-bottom: 2px;
}

.ticket-value {
  font-size: 14px;
  color: #f5f5f7;
  /* font-weight: bold; */
}

.ticket-assigned {
  display: flex;
  align-items: center;
}

.assigned-profile {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
}

.ticket-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #2c2c2e;
  padding: 6px;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
}

.all-templates-header {
  text-align: center;
  font-size: 24px;
  color: white;
  margin-bottom: 0px;
  margin-top: 50px;
}

.searchbar {
  padding: 15px;
  border-radius: 50px;
  background-color: #000000;
  border: 2px solid #808080; 
  display: flex;
  align-items: center; 
}

.searchbar input {
  border: none; 
  background-color: transparent; 
  color: white;
  font-size: 14px;
  outline: none;
  width: 100%; 
}
 
.history:hover {
  transform: scale(1.05); 
  background-color:#333333;
  border-radius: 10px;
  padding: 8px;
  
}

.historyscroll::-webkit-scrollbar {
  width: 0px; 
}

.chat-message-container::-webkit-scrollbar {
  width: 0px;
}

.scrollmessages::-webkit-scrollbar {
  width: 0px; 

}


.scrollmessages::-webkit-scrollbar-track {
  background: #888; 
  border-radius: 10px; 
}


.scrollmessages::-webkit-scrollbar-thumb {
  background-color: #333333; 
  border-radius: 10px; 
  border: 2px solid #2C2C2C; 
}


.scrollmessages::-webkit-scrollbar-thumb:hover {
  background-color: #555; 
}

